
































































































































































































































import moment from 'moment';
import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { ISale, ISalesResponse } from '@/interfaces/sale';
import { IClientType } from '@/interfaces/clienttype';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            moment,
            salesParams: {
                clients: [] as Array<IClient>,
                type: {} as IClientType,
                active: null,
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },

            sales: {
                data: [] as Array<ISale>,
                meta: {} as IPagination | any,
            } as ISalesResponse,
        };
    },
    mounted() {
        this.getSales();
    },
    methods: {
        getSales(page: number = 1): void {
            this.salesParams.page = page;

            this.get<ISalesResponse>('sales', {
                params: {
                    ...this.salesParams,
                    clients: this.salesParams.clients.map((p) => p.id),
                    type_id: this.salesParams.type ? this.salesParams.type.id : null,
                },
            }).then(({ data }) => {
                this.sales = data;
            });
        },
    },

});

export default component;
